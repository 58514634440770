import { notification } from "antd";

const sendNotification = (type, message, description, placement) => {
	notification[type]({
		message,
		description,
		placement,
		duration: 0,
	});
};

export { sendNotification };
