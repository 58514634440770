import React from "react";
import { Link } from "react-router-dom";
import { Layout, Button, Row, Col } from "antd";

import logo from "../../assets/img/logo_1.png";

const { Header } = Layout;

const HeaderComp = () => {
	return (
		<Header className="layout-header">
			<Row style={{ marginTop: "12px" }}>
				<Col className="menu-icon-logo" xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
					<div className="logo-content">
						<img src={logo} alt="Logo Magnatech S.A.C." />
					</div>
				</Col>
				<Col className="menu-action" xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
					<Button className="btn-link" type="link" shape="round" size="large">
						<Link to="/">Inicio</Link>
					</Button>
					<Button className="btn-contact" shape="round" size="large">
						<a href="https://www.magnatech.pe/contact">Contáctenos</a>
					</Button>
				</Col>
			</Row>
		</Header>
	);
};

export default HeaderComp;
