import React from "react";
import { Layout, Row, Col, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faYoutube, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const { Footer } = Layout;
const { Text } = Typography;

const FooterComp = () => {
	return (
		<Footer className="layout-footer">
			<Row style={{ borderTop: "1px dashed #ff7e02 ", paddingTop: "30px" }}>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="content-copy-right" style={{ marginBottom: "15px" }}>
					<Text style={{ fontSize: "14px", fontWeight: "400" }}>
						Copyright 2020, Magnatech
						<br />
						Todo los derechos reservados.
					</Text>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="items-social-media" style={{ marginBottom: "15px" }}>
					<a href="https://www.facebook.com/Magnatech.pe" rel="noopener noreferrer" target="_blank" style={{ padding: "2px 12px" }}>
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a rel="noopener noreferrer" href="https://www.youtube.com/channel/UCTVe_CoMI1xpXbUDSca3IGQ/featured?view_as=subscriber" target="_blank" style={{ padding: "2px 6px" }}>
						<FontAwesomeIcon icon={faYoutube} />
					</a>
					<a rel="noopener noreferrer" href="https://www.linkedin.com/company/magnatech-sac/" target="_blank" style={{ padding: "2px 9px" }}>
						<FontAwesomeIcon icon={faLinkedinIn} />
					</a>
				</Col>
			</Row>
		</Footer>
	);
};

export default FooterComp;
