import React from "react";

import FormStepTelco from "./telco";
import FormStepPoliza from "./poliza";

const FormStep = (params) => {
	const type = params.type || "";
	return (
		<>
			{type === "" && (
				<div>
					<p>Lo sentimos! No se pudo cargar el formulario. Por favor, intenterlo luego.</p>
				</div>
			)}
			{type === "telco" && <FormStepTelco />}
			{type === "poliza" && <FormStepPoliza />}
		</>
	);
};

export default FormStep;
