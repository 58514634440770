import React from "react";
import { Layout } from "antd";

import Header from "../../components/header";
import Footer from "../../components/footer";

const { Content } = Layout;

const HomeLayout = (props) => {
	return (
		<Layout style={{ backgroundColor: "#fff" }}>
			<Header />
			<Content className="layout-content-main">{props.children}</Content>
			<Footer />
		</Layout>
	);
};

export default HomeLayout;
