import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Modal, Checkbox, message, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";

import FormStep from "../../components/step-form";

const Demo = () => {
	let { type } = useParams();
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isAccepted, setIsAccepted] = useState(false);

	const handleShowFormStep = () => {
		if (isAccepted) {
			setIsVisibleForm(true);
		} else {
			message.error("Primero debe leer y aceptar nuestra Política de Privacidad", 3);
		}
	};

	function onChange(e) {
		setIsAccepted(e.target.checked);
	}

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			{isVisibleForm === false && (
				<>
					<Row gutter={15} justify="center" style={{ marginTop: "10px" }}>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
							<p>Bienvenido estimado usuario, a continuación, procederemos con la generación de la demo. Antes de generar la demo, por favor,tomar en cuenta lo siguiente:</p>
							<ul>
								<li>Los datos ingresados para generar la demostración, serán tratados solo y exclusivamente para la demo a generar.</li>
								<li>Al finalizar el proceso, se solicitará su correo para poder enviarle la demo generada.</li>
								<li>
									En caso de no ingresar información, la demo será generada con datos aleatorios y no válidos, la finalidad de la demo es poder mostrarle lo dinámico y versátil que
									pueden ser los documentos generados con nuestro servicio.
								</li>
							</ul>
							<p>
								<span className="btn-link-agreenment" style={{ cursor: "pointer" }} onClick={showModal}>
									<strong>Hacer click aquí para leer nuestra Política de Privacidad</strong>
								</span>
							</p>
							<p>
								<Checkbox onChange={onChange}>Acepto haber leído la Política de Privacidad y estar de acuerdo.</Checkbox>
							</p>
						</Col>
					</Row>
					<Row gutter={15} justify="center" style={{ marginTop: "10px" }}>
						<Col className="center-m" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
							<button className="btn-step-primary" onClick={handleShowFormStep}>
								Comenzar <RightOutlined />
							</button>
						</Col>
					</Row>
					<Modal title="Política de Privacidad" visible={isModalVisible} onCancel={handleOk} footer={[<Button onClick={handleOk}>Cerrar</Button>]}>
						<p>
							Titular: Magnatech S.A.C.
							<br />
							Dirección: Manuel Scorza 137 La Molina, Lima - Perú
							<br />
							Teléfono: (+511) 647 4543
							<br />
							E-mail: atencionalcliente@magnatech.pe
							<br />
							<br />
							Magnatech S.A.C te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios que puedan ser
							recabados por la navegación. En este sentido, el Titular te garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada
							en la Ley de Protección de Datos 29733.
							<br />
							<br />
							<strong>Obtención de datos personales</strong>
							<br />
							<br />
							Debe tener en cuenta que no es necesario ni obligatorio que registre datos personales para generar alguna demo, ya que, son datos cuyo objetivo es mostrar lo dinámico
							de nuestro servicio para generar demos con los datos que ingresó. El único dato personal que si debe de ser real, es su correo electrónico, eso se debe a que al
							correo registrado se le enviará la demo generada.
							<br />
							<br /> Nuestro sitio web podrá recoger información que desee registrar, para lo cual, se cumplirá con el principio de integridad y confidencialidad. Sus datos serán
							tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada. <br />
							<br />
							<strong>Finalidad del tratamiento de datos personales</strong>
							<br />
							<br />
							Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible. Es posible que sean enviados correos electrónicos periódicamente a
							través de nuestro sitio con ofertas especiales, nuevos servicios y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún
							beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
							<br />
							<br />
							<strong>Finalidad del tratamiento de datos personales</strong>
							<br />
							<br />
							Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con una orden
							judicial.
							<br />
							<br />
							Magnatech S.A.C. Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
						</p>
					</Modal>
				</>
			)}
			{isVisibleForm === true && <FormStep type={type} />}
		</>
	);
};

export default Demo;
