import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Steps, Typography, Form, Button, Input, Radio } from "antd";
import { UserOutlined, PhoneOutlined, MailOutlined, EnvironmentOutlined, IdcardOutlined, LeftOutlined, RightOutlined, CheckOutlined, EditOutlined } from "@ant-design/icons";

import { API } from "../../api";
import { htmlEncode } from "../../utils/transform";
import { sendNotification } from "../../utils/notification";

const { Step } = Steps;
const { Text } = Typography;

const steps = [
	{
		title: "Cliente",
		icon: <UserOutlined />,
	},
	{
		title: "",
		icon: <CheckOutlined />,
	},
];

const FormStepTelco = () => {
	const [current, setCurrent] = useState(0);
	const [parameters, setParameters] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [redirect, setRedirect] = useState(false);

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (parameters) {
					setRedirect(false);
					setIsLoading(true);

					await API("api/v1/service/demo/generar", { demo: { type: "telco", data: parameters } }, null, "POST");
					sendNotification(
						"success",
						"Demo generada!",
						<p
							dangerouslySetInnerHTML={htmlEncode(
								'Gracias por su tiempo! La demo generada ha sido enviada al correo electrónico indicado. Para mayor información, puede contactarse con nostros ingresando a la página web: <a href="https://www.magnatech.pe/contact" target="_blank">www.magnatech.pe/contact</a>'
							)}
						></p>,
						"topRight"
					);
					setIsLoading(false);
					setRedirect(true);
				}
			} catch (error) {
				setIsLoading(false);
				let errorResponse = error.response || "";
				let errorType = errorResponse !== "" ? error.response.data.status : "";

				if (errorType === "validation") {
					let messages = error.response.data.messages
						.map((message) => {
							return "<li>" + message + "</li>";
						})
						.join("");
					sendNotification(
						"warning",
						"Información no válida!",
						<p dangerouslySetInnerHTML={htmlEncode(`Lo sentimos! No se pudo generar la demo. Por favor, revise los detalles a continuación: <br/><ul>${messages}</ul>`)}></p>,
						"topRight"
					);
				} else {
					sendNotification(
						"error",
						"Demo no generada!",
						<p
							dangerouslySetInnerHTML={htmlEncode(
								'Lo sentimos! No se pudo generar la demo. Por favor, intentar luego o puede contactarse con nostros ingresando a la página web: <a href="https://www.magnatech.pe/contact" target="_blank">www.magnatech.pe/contact</a>'
							)}
						></p>,
						"topRight"
					);
					setRedirect(true);
				}
			}
		};

		fetchData();
	}, [parameters]);

	const handleSubmitForm = async (data) => {
		setParameters(data);
	};

	return (
		<Form name="basic" onFinish={handleSubmitForm} initialValues={{ documentType: "dni" }}>
			{redirect && <Redirect to="/" />}
			<Row gutter={15} justify="center" style={{ marginTop: "10px" }}>
				<Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={16}>
					<Steps current={current}>
						<Step title={steps[0].title} icon={steps[0].icon} />
						<Step title={steps[1].title} icon={steps[1].icon} />
					</Steps>
					<div className="steps-content">
						<div style={current === 0 ? { display: "block" } : { display: "none" }}>
							<Row gutter={[16, 16]} justify="center">
								<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
									<Text strong>Datos básicos (información para la demo)</Text>
								</Col>
							</Row>
							<Row gutter={[8, 0]} justify="center">
								<Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7} style={{ textAlign: "left" }}>
									<Form.Item className="document-type" name="documentType">
										<Radio.Group>
											<Radio value={"DNI"}>DNI</Radio>
											<Radio value={"RUC"}>RUC</Radio>
											<Radio value={"C.EXTRANJERIA"}>C. Extranjería</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
									<Form.Item name="numberID">
										<Input title="Número de documento" maxLength="11" placeholder="Número" prefix={<IdcardOutlined />} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[8, 0]} justify="center">
								<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
									<Form.Item name="name">
										<Input title="Nombres o Razón social" maxLength="50" placeholder="Nombres o Razón social" prefix={<EditOutlined />} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[8, 0]} justify="center">
								<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
									<Form.Item name="address">
										<Input title="Dirección" maxLength="50" placeholder="Dirección" prefix={<EnvironmentOutlined />} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 0]} justify="center" style={{ marginTop: "18px" }}>
								<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
									<Text strong>Contacto (información para la demo)</Text>
								</Col>
							</Row>
							<Row gutter={[8, 0]} justify="center">
								<Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
									<Form.Item name="phone">
										<Input title="Teléfono" maxLength="15" placeholder="Teléfono" prefix={<PhoneOutlined />} />
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
									<Form.Item name="email">
										<Input title="Email" maxLength="50" placeholder="Email" prefix={<MailOutlined />} />
									</Form.Item>
								</Col>
							</Row>
						</div>
						<div style={current === 1 ? { display: "block" } : { display: "none" }}>
							<Row gutter={[16, 16]} justify="center">
								<Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} style={{ textAlign: "left", marginTop: "50px" }}>
									<p>
										Genial, Ya está casi listo! Procederemos a generar la demo, pero, primero necesitamos que pueda registrar una dirección de email, ya que, a esa dirección de
										correo electrónico se le enviará la demo generada.
									</p>
								</Col>
							</Row>
							<Row gutter={[16, 16]} justify="center">
								<Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} style={{ textAlign: "left", marginBottom: "50px" }}>
									<Form.Item
										name="clientEmail"
										rules={[
											{
												type: "email",
												message: "El email registrado no es válido!",
											},
											{
												required: true,
												message: "El campo email es requerido!",
											},
										]}
									>
										<Input title="Email" maxLength="50" placeholder="Email" prefix={<MailOutlined />} />
									</Form.Item>
								</Col>
							</Row>
						</div>
					</div>
					<div className="steps-action" style={{ textAlign: "right" }}>
						{current > 0 && (
							<Button className="btn-step-prev" style={{ margin: "0 8px" }} onClick={() => prev()}>
								<LeftOutlined /> Anterior
							</Button>
						)}
						{current < steps.length - 1 && (
							<Button className="btn-step-primary" onClick={() => next()}>
								Siguiente <RightOutlined />
							</Button>
						)}
						{current === steps.length - 1 && (
							<Button loading={isLoading} className="btn-step-primary" htmlType="submit">
								Generar
							</Button>
						)}
					</div>
				</Col>
			</Row>
		</Form>
	);
};

export default FormStepTelco;
