import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography } from "antd";
import { PhoneOutlined, MedicineBoxOutlined } from "@ant-design/icons";

import imgHome from "../../assets/img/img-home.svg";
import imgArrowWhite from "../../assets/img/arrow-white.svg";

const { Title, Text } = Typography;

const Home = () => {
	return (
		<>
			<Row gutter={20} justify="center" style={{ marginBottom: "10vh" }}>
				<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ marginTop: "20px" }}>
					<Title style={{ color: "#646464" }}>Documentos demostrativos</Title>
					<br />
					<Text>
						Bienvenido a la página de generación de demostraciones para Telecomunicaciones, donde podrá generar modelos interactivos y poder apreciar la versatilidad y dinamismo a
						la hora de componer documentos, que pueden ser enviados masivamente entre sus áreas y clientes.
					</Text>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={{ span: 10, offset: 4 }} xxl={{ span: 10, offset: 4 }} style={{ marginTop: "20px" }}>
					<img className="img-home" src={imgHome} alt="Imagen inicio de landing page" />
				</Col>
			</Row>
			<Row gutter={15} justify="center" style={{ marginTop: "10vh" }}>
				<Col span={24} style={{ textAlign: "center", marginTop: "15px" }}>
					<Title level={2} style={{ color: "#646464", fontWeight: "600", fontSize: "40px" }}>
						Demostraciones actuales
					</Title>
				</Col>
				<Col className="card-demo" xs={24} sm={12} md={10} lg={8} xl={6} xxl={5}>
					<Col className="card-demo-header" span={24}>
						<PhoneOutlined style={{ fontSize: "50px" }} />
						<br />
						<Text className="card-demo-title">DEMO TELCO</Text>
						<img className="arrow-white" src={imgArrowWhite} alt="arrow white" />
					</Col>
					<Col span={24} style={{ textAlign: "justify", padding: "30px" }}>
						<p>
							En esta demostración para Telco, podrá apreciar el dinamismo que puede brindarle a sus clientes, al componer documentos en distintos formatos, de acorde a la
							necesidad. Mostrándole a sus clientes, un documento más interactivo y moderno, sin dejar de lado, la información completa que se necesita brindar.
						</p>
					</Col>
					<Col span={24} style={{ textAlign: "center", padding: "5px 30px 30px 30px" }}>
						<Link className="btn-view-demo" to="/demo/telco">
							Generar demo
						</Link>
					</Col>
				</Col>
				<Col className="card-demo" xs={24} sm={12} md={10} lg={8} xl={6} xxl={5}>
					<Col className="card-demo-header" span={24}>
						<MedicineBoxOutlined style={{ fontSize: "50px" }} />
						<br />
						<Text className="card-demo-title">DEMO SEGURO</Text>
						<img className="arrow-white" src={imgArrowWhite} alt="arrow white" />
					</Col>
					<Col span={24} style={{ textAlign: "justify", padding: "30px" }}>
						<p>
							En esta demostración para Póliza de Seguro, podrá apreciar el dinamismo que puede brindarle a sus clientes, al componer documentos en distintos formatos, de acorde a
							la necesidad. Mostrándole a sus clientes, un documento más interactivo y moderno, sin dejar de lado, la información completa que se necesita brindar.
						</p>
					</Col>
					<Col span={24} style={{ textAlign: "center", padding: "5px 30px 30px 30px" }}>
						<Link className="btn-view-demo" to="/demo/poliza">
							Generar demo
						</Link>
					</Col>
				</Col>
			</Row>
		</>
	);
};

export default Home;
