import axios from "axios";

const baseUrl = "https://mgtlm01.magnatech.pe:3001/";

export const API = (url, data, headers, method) =>
	axios({
		method,
		url: baseUrl + url,
		data,
		headers,
	});
