import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Layouts
import Homelayout from "../layouts/home";

// Pages
import Home from "../pages/home";
import Demo from "../pages/demo";

const Routes = () => {
	const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
		<Route
			{...rest}
			render={(props) => (
				<Layout {...props}>
					<Component {...props} />
				</Layout>
			)}
		/>
	);

	return (
		<BrowserRouter>
			<Switch>
				<AppRoute exact path="/" layout={Homelayout} component={Home} />
				<AppRoute exact path="/demo/:type" layout={Homelayout} component={Demo} />
				<Redirect from="*" to="/" />
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
