import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import "moment/locale/es";

import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.css";
import "./assets/styles/customStyles.css";

import Routes from "./routes";

//Configuración global de localization moment
moment.locale("es");

const App = () => <Routes />;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
